footer {
    background: #2c353c;
    color: #fff;
    padding-bottom: 150px;
    position: relative;

    .footer-content {
        @include width-restrict;
    }

    .logo {
        border: 0;
        padding: 0 0 20px 0;
        text-align: left;
        max-width: 300px;
        justify-content: flex-start;
    }

    .contact {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 60px;

        p {
            font-style: italic;
            max-width: 65%;
            text-align: left;
            margin: 0 auto;

            a {
                display: block;
                text-align: right;
            }
        }

        a {
            color: #fff;
            display: block;
            text-decoration: none;
            font-weight: 600;
        }

        hr {
            width: 100%;
            border: 0;
            background: rgba(255, 255, 255, 0.05);
            height: 2px;
            margin: 60px 0;
        }
    }

    .attribution {
        background: #000;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 10px 0;
        text-align: center;
        
        a {
            color: #ccc;
            text-decoration: none;
            font-size: 12px;
            text-transform: lowercase;
        }
    }

    .copyright{
        text-align: center;
        white-space: nowrap;
        margin: 60px auto 0;
        min-width: 100%;
    }
}

.newsletter-signup {
    margin: 0;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;

    @media all and (min-width: $medium) {
        width: auto;
        margin: 0 0 0 auto;
    }

    > div {
        background: transparent;
        padding: 0;
        position: relative;

        @media all and (min-width: $medium) {
            margin-left: 60px;
        }

        h2 {
            font-size: 16px;
            font-weight: normal;
            margin: 0 0 15px;
        }
    }
}

.mc-field-group {
    position: relative;
    height: 44px;

    label {
        position: absolute;
        top: 53%;
        left: 15px;
        color: #000;
        z-index: 1;
        transform: translateY(-50%);
        pointer-events: none;
        opacity: 1;

        .signup-active & {
            opacity: 0;
        }
    }

    .email {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.optionalParent {
    height: 10px;

    input[type='submit'] {
        border: 0;
        background: #960018;
        color: #fff;
        font-weight: bold;
        position: relative;
        top: -37px;
        left: calc(100% - 76px);
        height: 36px;
        border-radius: 2px;
    }
}

.brandingLogo {
    a {
        float: none;
        position: absolute;
        bottom: -55px;
        right: 0;
    }
}
