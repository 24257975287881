.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    margin: 0;
    text-align: center;

    @media all and (min-width: $medium){
        max-width: 315px;
        min-width: 315px;
    }

    img {
        max-width: 80%;
        max-height: 70%;
    }
}
