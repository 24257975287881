.ministries {
    padding-top: 185px;    
    padding-bottom: 100px;
    background-image: url('/assets/dist/images/wavy-lines.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 25% auto;

    .section-content {
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
    }

    h2 {
        text-align: center;
        margin-bottom: 60px;
        width: 100%;
    }

    .accordions {
        width: 100%;

        @media all and (min-width: $medium) {
            width: calc(55% - 30px);
            margin-right: 30px;
        }
    }

    .accordion-details {
        text-align: left;
        width: 100%;
        margin-top: 40px;

        @media all and (min-width: $medium) {
            width: 45%;
            margin-top: 0;
        }

        figure {
            margin: 0 0 40px 0;
            max-width: 100%;
            position: relative;

            &::after,
            &::before{
                content: '';
                display: block;
                position: absolute;
                width: 35%;
                height: 50%;
                background: #3c72fc;
                top: -10px;
                left: -10px;
                z-index: -1;
            }

            &::after{
                top: auto;
                left: auto;
                right: -10px;
                bottom: -5px;
            }

            img {
                width: 100%;
            }
        }
    }
}
