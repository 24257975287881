.accordions{
    .accordion{
        text-align: left;
        box-shadow: 0 0 20px rgba(0, 0, 0, .05);
        background: #f2f4f8;
        margin: 0 0 15px 0;

        &.acc-open{
            background: #fff;

            .acc-content{
                height: auto;
                padding: 0px 40px 40px;
            }
        }

        .acc-title{
            position: relative;
            font-size: 16px;
            color: #000;
            padding: 30px 40px;
            margin: 0;

            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                right: 40px;
                top: 50%;
                transform: translateY(-50%);
                background: transparent url('/assets/dist/images/acc-plus.png') no-repeat center;
            }

            &:hover,
            &:focus{
                cursor: pointer;
                background: #fff;
            }
        }

        .acc-content{
            height: 0;
            overflow: hidden;
            padding: 0;

            p{
                color: #0f0d1d;
            }
        }

        &.acc-open{
            .acc-title{
                &::after{
                    content: '';
                    background-image: url('/assets/dist/images/acc-minus.png');
                }
            }
        }
    }
}