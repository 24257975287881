.callout-section{
    width: 100%;
    background: transparent url('/assets/dist/images/Callout-Background.png') no-repeat center center;
    background-size: cover;
    padding: 70px 0;

    .section-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    h2{
        @include Jost-Black;
        color: #fff;
        font-size: 40px;
        text-transform: uppercase;
        letter-spacing: -.02rem;
        width: 100%;

        @media all and (min-width: $medium){
            max-width: 50%;
        }
    }
}