.homepage .sermons {
    padding-bottom: 0;
}

.sermon-overview {
    display: flex;
    margin-bottom: 15px;
    position: relative;
    flex-wrap: wrap;

    @media all and (min-width: $medium) {
        flex-wrap: nowrap;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100px;
        height: 100px;
        background: #3c72fc;
        top: 30px;
        left: -30px;
        z-index: 5;
    }

    &::after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-left: 16px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        top: 70px;
        left: 15px;
        z-index: 6;
    }

    .details {
        order: 2;
        width: 100%;
        padding: 40px 0 0 0;

        @media all and (min-width: $medium) {
            width: 35%;
            padding: 0 0 0 70px;
        }

        h2 {
            line-height: 1.15em;
        }
    }

    .video-embed {
        iframe {
            max-width: 100%;
        }
    }

    .link-images {
        order: 1;
        position: relative;
        padding-bottom: 50px;
        width: 100%;

        @media all and (min-width: $medium) {
            width: 75%;
        }

        figure {
            width: 100%;
            height: auto;
            margin: 0;

            &.main-image {
                overflow: hidden;
                margin: 0 0 -230px -40px;
                width: 100vw;
                max-height: 450px;

                @media all and (min-width: $medium) {
                    width: 75%;
                    max-height: 550px;
                    margin: 0;
                }
            }

            &.sub-image {
                overflow: hidden;
                position: static;
                width: 100%;
                margin: 20px 0;
                top: 90px;
                text-align: center;

                @media all and (min-width: $medium) {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    top: auto;
                    border-top: 15px solid #fff;
                    border-left: 15px solid #fff;
                    max-width: 60vw;
                    margin: 0;
                    width: 450px;
                }
            }

            img {
                vertical-align: middle;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.recent-sermons {
    display: flex;
    width: 100%;
    position: relative;
    bottom: -85px;
    flex-wrap: wrap;

    @media all and (min-width: $medium) {
        flex-wrap: nowrap;
    }

    .sermon {
        width: 100%;
        padding: 30px;
        background: #fff;
        box-shadow: 10px 0 60px rgba(0, 0, 0, 0.08);
        font-weight: inherit;

        @media all and (min-width: $medium) {
            padding: 50px 60px;
        }
    }

    .date {
        display: block;
        padding: 15px;
        color: #3c72fc;
        font-size: 24px;
        @include Jost;
        background: #ebf1ff;
        text-align: center;
        margin-bottom: 20px;

        @media all and (min-width: $medium) {
            float: left;
            margin-right: 40px;
            margin-bottom: 0;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 20px;
        @include Jost-Black;
        margin-bottom: 5px;
        color: #0f0d1d;
        overflow: hidden;
    }

    p {
        font-size: 16px;
        color: #726f84;
        line-height: 32px;
        overflow: hidden;
        margin: 0;
    }
}

.sermons-container {
    display: flex;
    flex-wrap: wrap;

    a {
        display: block;
        width: 100%;
        padding: 25px 25px 40px;
        box-sizing: border-box;
        margin: 30px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        position: relative;
        transition: all 0.33s ease-in-out;
        top: 0;

        @media all and (min-width: $medium){
            width: calc(33% - 40px);
        }

        &:hover,
        &:focus {
            box-shadow: 0 20px 20px rgb(0 0 0 / 25%);
            top: -15px;
        }
    }

    span.date {
        position: absolute;
        top: -31px;
        left: 0;
        background: #3c72fc;
        padding: 5px 20px;
        color: #fff;
        font-weight: normal;
        font-size: 14px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-weight: normal;
    }
}
