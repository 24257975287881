@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;600;900&display=swap');

@mixin Jost-Light{
    font-family: 'Jost', sans-serif;
    font-weight: 400;
}
@mixin Jost{
    font-family: 'Jost', sans-serif;
    font-weight: 300;
}
@mixin Jost-Bold{
    font-family: 'Jost', sans-serif;
    font-weight: 600;
}
@mixin Jost-Black{
    font-family: 'Jost', sans-serif;
    font-weight: 900;
}

$small: 768px;
$medium: 1280px;
$large: 1440px;
$xlarge: 2400px;