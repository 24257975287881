.page-content {
  .scripture-passage {
    display: block;
    text-align: center;
    margin-bottom: 40px;
    background: #f2f2f2;
    font-weight: 500;
    border-radius: 10px;
    padding: 15px;
    font-size: 24px;
    margin: 40px auto;

    a {
      display: block;
      text-align: right;
      color: #0f0d1d;
      font-weight: 600;
    }
  }

  ul:not([class]) {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      padding: 0 0 0 20px;
      position: relative;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #3c72fc;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }

  a {
    font-weight: bold;
    color: #3c72fc;
    text-decoration: none;
  }
}

.subpage .page-content {
  .section-content {
    text-align: left;
    width: 980px;
  }

  .section-icon {
    display: block;
    width: 250px;
    height: 85px;
    margin: 0 auto 40px;
    background: #0f0d1d;
    padding: 20px;
    border-radius: 100px;

    &.foundation {
      .hook {
        transform: translateY(0);
        transition: all 0.33s ease-in-out;
      }
      &:hover,
      &:focus {
        .hook {
          transform: translateY(50px);
        }
      }
    }

    &.serve:hover,
    &.serve:focus {
      .heart {
        transform-origin: center;
        animation: heartbeat 1.5s infinite;
      }
    }
  }

  figure {
    margin: 0 0 40px 0;
    max-width: 100%;
    position: relative;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 35%;
      height: 50%;
      background: #3c72fc;
      top: -10px;
      left: -10px;
      z-index: -1;
    }

    &::after {
      top: auto;
      left: auto;
      right: -10px;
      bottom: -5px;
    }

    img {
      width: 100%;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.75rem;

    &.large-text {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }

  h2 {
    font-size: 36px;
    text-align: left;
    margin: 20px 0 5px;
  }

  h3 {
    font-size: 24px;
    margin: 20px 0 5px;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.15);
  }
  10% {
    transform: scale(1.1);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.1);
  }
}

.craft-image {
  width: 250px;
  margin: 0 auto 50px;
  display: block;
}
