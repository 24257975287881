section{
    padding: 100px 0 150px;

    &.image-background{
        background-size: cover;
        position: relative;

        .section-content{

            > *{
                position: relative;
                z-index: 1;
            }

            &:before{
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .8);
                z-index: 1;
            }
        }
    }

    .section-content{
        @include width-restrict;
        background-size: cover;

        &.image-section{

            @media all and (min-width: $medium){
                display: flex;
                flex-wrap: nowrap;
            }

            figure{
                min-width: 80%;
                align-self: center;
                margin: 0 auto;

                @media all and (min-width: $medium){
                    min-width: 500px;
                    margin: auto 0 auto 100px;
                }
            }

            &.image-left{
                align-items: center;

                figure{
                    margin: 0 50px 0 0;
                    min-width: 250px;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        &.centered{
            text-align: center;
        }
    }

    &.light-section{
        background: #fff;

        h2,
        h3{
            color: #0f0d1d;
        }

        p{
            color: #222;
        }
    }

    &.gray-section{
        background: #f2f4f8;

        h2,
        h3{
            color: #0f0d1d;
        }

        p{
            color: #222;
        }
    }

    h2,
    h3{
        @include Jost-Black;
        color: #fff;
        text-transform: uppercase;
        font-size: 35px;
        letter-spacing: -.002rem;
        margin: 0 0 10px 0;

        @media all and (min-width: $medium){
            font-size: 50px;
        }
    }

    h3{
        font-size: 18px;

        @media all and (min-width: $medium){
            font-size: 24px;
        }
    }

    .callout-text{
        @include Jost;
        font-size: 18px;
        color: #8f8da0;
    }

    .scripture-reference{
        color: #fff;
        font-size: 12px;
        text-decoration: none;
        font-weight: bold;
        vertical-align: super;    
    }

    p{
        color: #f2f4f8;
    }
}