/*
SCSS Build File


General Layout
*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;600;900&display=swap");
html, body {
  height: 100%;
  min-height: 100%;
  background: #0f0d1d;
  margin: 0;
  padding: 0;
  font-family: "Jost", sans-serif;
  position: relative;
  z-index: -1;
}

.sr-only {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  display: block;
}

/*

Header Styles

*/
header {
  position: absolute;
  display: flex;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  height: 115px;
  box-sizing: border-box;
  top: 0;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0;
  text-align: center;
}
@media all and (min-width: 1280px) {
  .logo {
    max-width: 315px;
    min-width: 315px;
  }
}
.logo img {
  max-width: 80%;
  max-height: 70%;
}

body.nav-open {
  overflow: hidden;
}

nav.primary {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
nav.primary ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
}
@media all and (min-width: 1280px) {
  nav.primary ul {
    flex-direction: row;
    position: static;
    width: auto;
  }
}
nav.primary ul.navigation {
  position: fixed;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 150px);
  background: rgba(0, 0, 0, 0.9);
  left: 100%;
  top: 115px;
  z-index: 10;
  flex-direction: column;
  transition: 0.33s all ease-in-out;
}
@media all and (min-width: 1280px) {
  nav.primary ul.navigation {
    position: static;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    background: transparent;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
.nav-open nav.primary ul.navigation {
  left: 0;
}
nav.primary ul.navigation li {
  padding: 0;
  line-height: 100px;
  min-height: 100px;
  margin: 0;
  position: relative;
}
@media all and (min-width: 1280px) {
  nav.primary ul.navigation li {
    line-height: 1rem;
    height: auto;
    margin-right: 40px;
    min-height: 0;
  }
  nav.primary ul.navigation li:hover .subnav {
    opacity: 1;
    pointer-events: all;
    top: 100%;
  }
}
nav.primary ul.navigation .nav-item {
  padding: 0 40px;
  width: 100%;
  display: block;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
@media all and (min-width: 1280px) {
  nav.primary ul.navigation .nav-item {
    width: auto;
    height: auto;
    border: 0;
    padding: 0;
    display: inline-block;
  }
}
nav.primary ul.navigation .nav-item::after {
  position: absolute;
  height: 2px;
  background: #fff;
  width: 0;
  bottom: -5px;
  left: 0;
  border-radius: 2px;
  transition: all 0.33s ease-in-out;
}
@media all and (min-width: 1280px) {
  nav.primary ul.navigation .nav-item::after {
    content: "";
  }
}
nav.primary ul.navigation .nav-item:hover {
  cursor: pointer;
}
nav.primary ul.navigation .nav-item:hover::after {
  width: 100%;
}
nav.primary ul.navigation .nav-item.active::after {
  width: 15px;
}
nav.primary ul.navigation .nav-item.active:hover::after {
  width: 100%;
}
nav.primary ul.social-media {
  padding-right: 25px;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  margin-left: auto;
}
nav.primary ul.social-media li {
  margin-right: 0;
}
nav.primary ul.social-media a {
  width: 26px;
  height: 100%;
  display: block;
  background: transparent no-repeat center;
  background-size: 14px;
}
nav.primary ul.social-media a.facebook {
  background-image: url("/assets/dist/images/facebook.png");
}
nav.primary ul.social-media a.youtube {
  background-image: url("/assets/dist/images/youtube-brands.svg");
}
nav.primary li {
  color: #fff;
  font-size: 18px;
  line-height: 115px;
  margin-right: 30px;
}
nav.primary a {
  color: #fff;
  text-decoration: none;
  position: relative;
}
nav.primary ul.subnav {
  display: block;
  border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  margin-top: -3px;
  position: relative;
}
@media all and (min-width: 1280px) {
  nav.primary ul.subnav {
    position: absolute;
    left: 0;
    opacity: 0;
    pointer-events: none;
    top: calc(100% - 20px);
    transition: all 0.33s ease-out;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    border: 0;
    background: rgba(0, 0, 0, 0.75);
  }
}
nav.primary ul.subnav li {
  height: auto;
  min-height: 0;
  line-height: 2.75rem;
  background: #000;
  border: 0;
}
nav.primary ul.subnav li a {
  border: 0;
  padding: 0 0 0 60px;
  display: block;
}
@media all and (min-width: 1280px) {
  nav.primary ul.subnav li a {
    padding: 10px 0;
    white-space: nowrap;
  }
}

/* mobile menu toggle button */
.menu-toggle {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 116px;
  padding: 40px 25px;
  cursor: pointer;
  box-sizing: border-box;
  order: 4;
}
@media all and (min-width: 1280px) {
  .menu-toggle {
    display: none;
  }
}
.menu-toggle span {
  margin: 0 auto;
  position: relative;
  top: 12px;
  transition-duration: 0s;
  transition-delay: 0.2s;
  transition: background-color 0.3s;
  width: 40px;
  height: 6px;
  background-color: #fff;
  display: block;
  opacity: 1;
}
.menu-toggle span::before, .menu-toggle span::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 6px;
  background-color: #fff;
  display: block;
  opacity: 1;
}
.menu-toggle span::before {
  margin-top: -12px;
  transition-property: margin, transform;
  transition-duration: 0.2s;
  transition-delay: 0.2s, 0;
}
.menu-toggle span::after {
  margin-top: 12px;
  transition-property: margin, transform;
  transition-duration: 0.2s;
  transition-delay: 0.2s, 0;
}
.nav-open .menu-toggle span {
  background-color: rgba(0, 0, 0, 0);
  transition: 0.3s background-color;
}
.nav-open .menu-toggle span::before {
  margin-top: 0;
  transform: rotate(45deg);
  transition-delay: 0, 0.2s;
}
.nav-open .menu-toggle span::after {
  margin-top: 0;
  transform: rotate(-45deg);
  transition-delay: 0, 0.2s;
}

.quick-contact {
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: #e7e7e7;
  position: absolute;
  bottom: -50px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 14px 0px;
}
@media all and (min-width: 1280px) {
  .quick-contact {
    width: 320px;
    flex-direction: column;
    position: static;
    padding: 0px 25px;
    border: 0;
    text-align: left;
  }
}
.quick-contact a {
  color: #fff;
  text-decoration: none;
  margin-left: 15px;
}
@media all and (min-width: 1280px) {
  .quick-contact a {
    margin-left: 0;
  }
}

.qc-text {
  color: #fff;
  opacity: 0.8;
}

@font-face {
  font-display: block;
  font-family: Roboto;
  src: url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format("woff");
}
@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-weight: 600;
  src: url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format("woff");
}
@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-weight: 700;
  src: url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format("woff");
}
#sib-container {
  background: transparent !important;
}
#sib-container * {
  float: right;
  clear: both;
}

#sib-container input:-ms-input-placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container input::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container textarea::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container .entry__field input {
  color: #222;
}

.featured-newsletter-signup {
  background-image: url("/assets/src/images/contact-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
}

/*

Banner Styles
*/
:root {
  --nav-height: 165px;
}
@media all and (min-width: 1280px) {
  :root {
    --nav-height: 115px;
  }
}

.page-banner {
  position: relative;
  z-index: -1;
  background: transparent;
  height: calc(100vh - var(--nav-height));
  padding-top: var(--nav-height);
  background: #0f0d1d url("/assets/dist/images/banner-background.png") no-repeat 100% top;
  background-position: center var(--nav-height);
  overflow: hidden;
  background-size: 125vw calc(100% - var(--nav-height));
}
@media all and (min-width: 1280px) {
  .page-banner {
    background-size: 100vw calc(100% - var(--nav-height));
  }
}
@media all and (min-width: 2100px) {
  .page-banner {
    background-size: 1894px calc(100% - var(--nav-height));
  }
}
.page-banner .page-banner-content {
  width: 100%;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - var(--nav-height));
}
@media all and (min-width: 1280px) {
  .page-banner .page-banner-content {
    max-width: calc(100% - 480px);
  }
}
.page-banner h1 {
  font-family: "Jost", sans-serif;
  font-weight: 900;
  letter-spacing: -0.002rem;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  margin: 0;
  font-size: 50px;
  text-indent: 10px;
  line-height: 1.25em;
}
@media all and (min-width: 1280px) {
  .page-banner h1 {
    font-size: 90px;
    text-indent: 17px;
    text-align: left;
  }
}
.page-banner .btn.btn-primary {
  background: transparent;
  color: transparent;
  position: absolute;
  left: 0;
  width: 100vw;
  height: calc(100% - var(--nav-height));
  top: var(--nav-height);
  margin: 0;
  padding: 0;
}

.subpage-banner {
  padding-top: 115px;
  height: 250px;
  display: flex;
  align-items: center;
  background: #0f0d1d url("/assets/dist/images/banner-background.png") no-repeat 100% bottom;
}
.subpage-banner h1 {
  width: 100%;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  color: #fff;
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
}
@media all and (min-width: 1280px) {
  .subpage-banner h1 {
    max-width: calc(100% - 480px);
  }
}
@media all and (min-width: 1280px) {
  .subpage-banner h1 {
    font-size: 50px;
  }
}

.mouse {
  background: #4e5559 linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
  position: relative;
  width: 26px;
  height: 44px;
  border-radius: 100px;
  background-size: 100% 200%;
  opacity: 0.5;
  margin: 0 auto;
  transition: all 0.33s ease-out;
  animation: colorSlide 15s linear infinite, nudgeMouse 15s ease-out infinite;
}
.mouse:before, .mouse:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.mouse:before {
  width: 23px;
  height: 41px;
  background-color: #222a30;
  border-radius: 100px;
}
.mouse:after {
  background-color: #ffffff;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  animation: trackBallSlide 15s linear infinite;
}
.mouse:hover, .mouse:focus {
  opacity: 1;
  cursor: pointer;
}

@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: #4e5559;
  }
  29.99% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  30% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  51% {
    background-color: #4e5559;
  }
  59% {
    background-color: #ffffff;
    background-position: 0% 0%;
  }
  60% {
    background-color: #4e5559;
    background-position: 0% 100%;
  }
  80% {
    background-position: 0% 0%;
  }
  81% {
    background-color: #4e5559;
  }
  90%, 100% {
    background-color: #ffffff;
  }
}
@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(2.5px);
  }
  14% {
    opacity: 0;
    transform: scale(0.2) translateY(20px);
  }
  15%, 19% {
    opacity: 0;
    transform: scale(0.2) translateY(-10px);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  36% {
    opacity: 1;
    transform: scale(0.9) translateY(2.5px);
  }
  44% {
    opacity: 0;
    transform: scale(0.2) translateY(20px);
  }
  45%, 49% {
    opacity: 0;
    transform: scale(0.2) translateY(-10px);
  }
  58%, 59.99% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
  66% {
    opacity: 1;
    transform: scale(0.9) translateY(2.5px);
  }
  74% {
    opacity: 0;
    transform: scale(0.2) translateY(20px);
  }
  75%, 79% {
    opacity: 0;
    transform: scale(0.2) translateY(-10px);
  }
  88%, 100% {
    opacity: 1;
    transform: scale(1) translateY(-10px);
  }
}
@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(8px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(8px);
  }
  90% {
    transform: translateY(0);
  }
}
@keyframes nudgeText {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(2px);
  }
  30% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(2px);
  }
  60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(0);
  }
}
@keyframes colorText {
  21% {
    color: #4e5559;
  }
  30% {
    color: #ffffff;
  }
  51% {
    color: #4e5559;
  }
  60% {
    color: #ffffff;
  }
  81% {
    color: #4e5559;
  }
  90% {
    color: #ffffff;
  }
}
/*


Content Styles

*/
.page-content .scripture-passage {
  display: block;
  text-align: center;
  margin-bottom: 40px;
  background: #f2f2f2;
  font-weight: 500;
  border-radius: 10px;
  padding: 15px;
  font-size: 24px;
  margin: 40px auto;
}
.page-content .scripture-passage a {
  display: block;
  text-align: right;
  color: #0f0d1d;
  font-weight: 600;
}
.page-content ul:not([class]) {
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-content ul:not([class]) li {
  padding: 0 0 0 20px;
  position: relative;
}
.page-content ul:not([class]) li:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #3c72fc;
  position: absolute;
  left: 0;
  top: 8px;
}
.page-content a {
  font-weight: bold;
  color: #3c72fc;
  text-decoration: none;
}

.subpage .page-content .section-content {
  text-align: left;
  width: 980px;
}
.subpage .page-content .section-icon {
  display: block;
  width: 250px;
  height: 85px;
  margin: 0 auto 40px;
  background: #0f0d1d;
  padding: 20px;
  border-radius: 100px;
}
.subpage .page-content .section-icon.foundation .hook {
  transform: translateY(0);
  transition: all 0.33s ease-in-out;
}
.subpage .page-content .section-icon.foundation:hover .hook, .subpage .page-content .section-icon.foundation:focus .hook {
  transform: translateY(50px);
}
.subpage .page-content .section-icon.serve:hover .heart, .subpage .page-content .section-icon.serve:focus .heart {
  transform-origin: center;
  animation: heartbeat 1.5s infinite;
}
.subpage .page-content figure {
  margin: 0 0 40px 0;
  max-width: 100%;
  position: relative;
}
.subpage .page-content figure::after, .subpage .page-content figure::before {
  content: "";
  display: block;
  position: absolute;
  width: 35%;
  height: 50%;
  background: #3c72fc;
  top: -10px;
  left: -10px;
  z-index: -1;
}
.subpage .page-content figure::after {
  top: auto;
  left: auto;
  right: -10px;
  bottom: -5px;
}
.subpage .page-content figure img {
  width: 100%;
}
.subpage .page-content p {
  font-size: 18px;
  line-height: 1.75rem;
}
.subpage .page-content p.large-text {
  font-size: 1.5rem;
  line-height: 2.25rem;
}
.subpage .page-content h2 {
  font-size: 36px;
  text-align: left;
  margin: 20px 0 5px;
}
.subpage .page-content h3 {
  font-size: 24px;
  margin: 20px 0 5px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.15);
  }
  10% {
    transform: scale(1.1);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.1);
  }
}
.craft-image {
  width: 250px;
  margin: 0 auto 50px;
  display: block;
}

.btn {
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background: #000;
  display: inline-block;
  width: auto;
  padding: 20px 40px;
  text-decoration: none;
  letter-spacing: 0.05rem;
  transition: all 0.33s ease-in-out;
}
.btn.btn-primary {
  background: #960018;
}
.btn:hover, .btn:focus {
  background: #fff;
  color: #000;
}
.btn.btn-secondary {
  background: #fff;
  color: #0f0d1d;
}
.btn.btn-secondary:hover, .btn.btn-secondary:focus {
  color: #fff;
  background: #0f0d1d;
}

section {
  padding: 100px 0 150px;
}
section.image-background {
  background-size: cover;
  position: relative;
}
section.image-background .section-content > * {
  position: relative;
  z-index: 1;
}
section.image-background .section-content:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}
section .section-content {
  width: 100%;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  background-size: cover;
}
@media all and (min-width: 1280px) {
  section .section-content {
    max-width: calc(100% - 480px);
  }
}
@media all and (min-width: 1280px) {
  section .section-content.image-section {
    display: flex;
    flex-wrap: nowrap;
  }
}
section .section-content.image-section figure {
  min-width: 80%;
  align-self: center;
  margin: 0 auto;
}
@media all and (min-width: 1280px) {
  section .section-content.image-section figure {
    min-width: 500px;
    margin: auto 0 auto 100px;
  }
}
section .section-content.image-section.image-left {
  align-items: center;
}
section .section-content.image-section.image-left figure {
  margin: 0 50px 0 0;
  min-width: 250px;
}
section .section-content.image-section.image-left figure img {
  width: 100%;
  height: 100%;
}
section .section-content.centered {
  text-align: center;
}
section.light-section {
  background: #fff;
}
section.light-section h2,
section.light-section h3 {
  color: #0f0d1d;
}
section.light-section p {
  color: #222;
}
section.gray-section {
  background: #f2f4f8;
}
section.gray-section h2,
section.gray-section h3 {
  color: #0f0d1d;
}
section.gray-section p {
  color: #222;
}
section h2,
section h3 {
  font-family: "Jost", sans-serif;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  font-size: 35px;
  letter-spacing: -0.002rem;
  margin: 0 0 10px 0;
}
@media all and (min-width: 1280px) {
  section h2,
section h3 {
    font-size: 50px;
  }
}
section h3 {
  font-size: 18px;
}
@media all and (min-width: 1280px) {
  section h3 {
    font-size: 24px;
  }
}
section .callout-text {
  font-family: "Jost", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #8f8da0;
}
section .scripture-reference {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  vertical-align: super;
}
section p {
  color: #f2f4f8;
}

.homepage .sermons {
  padding-bottom: 0;
}

.sermon-overview {
  display: flex;
  margin-bottom: 15px;
  position: relative;
  flex-wrap: wrap;
}
@media all and (min-width: 1280px) {
  .sermon-overview {
    flex-wrap: nowrap;
  }
}
.sermon-overview::before {
  content: "";
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  background: #3c72fc;
  top: 30px;
  left: -30px;
  z-index: 5;
}
.sermon-overview::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 16px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  top: 70px;
  left: 15px;
  z-index: 6;
}
.sermon-overview .details {
  order: 2;
  width: 100%;
  padding: 40px 0 0 0;
}
@media all and (min-width: 1280px) {
  .sermon-overview .details {
    width: 35%;
    padding: 0 0 0 70px;
  }
}
.sermon-overview .details h2 {
  line-height: 1.15em;
}
.sermon-overview .video-embed iframe {
  max-width: 100%;
}
.sermon-overview .link-images {
  order: 1;
  position: relative;
  padding-bottom: 50px;
  width: 100%;
}
@media all and (min-width: 1280px) {
  .sermon-overview .link-images {
    width: 75%;
  }
}
.sermon-overview .link-images figure {
  width: 100%;
  height: auto;
  margin: 0;
}
.sermon-overview .link-images figure.main-image {
  overflow: hidden;
  margin: 0 0 -230px -40px;
  width: 100vw;
  max-height: 450px;
}
@media all and (min-width: 1280px) {
  .sermon-overview .link-images figure.main-image {
    width: 75%;
    max-height: 550px;
    margin: 0;
  }
}
.sermon-overview .link-images figure.sub-image {
  overflow: hidden;
  position: static;
  width: 100%;
  margin: 20px 0;
  top: 90px;
  text-align: center;
}
@media all and (min-width: 1280px) {
  .sermon-overview .link-images figure.sub-image {
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;
    border-top: 15px solid #fff;
    border-left: 15px solid #fff;
    max-width: 60vw;
    margin: 0;
    width: 450px;
  }
}
.sermon-overview .link-images figure img {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recent-sermons {
  display: flex;
  width: 100%;
  position: relative;
  bottom: -85px;
  flex-wrap: wrap;
}
@media all and (min-width: 1280px) {
  .recent-sermons {
    flex-wrap: nowrap;
  }
}
.recent-sermons .sermon {
  width: 100%;
  padding: 30px;
  background: #fff;
  box-shadow: 10px 0 60px rgba(0, 0, 0, 0.08);
  font-weight: inherit;
}
@media all and (min-width: 1280px) {
  .recent-sermons .sermon {
    padding: 50px 60px;
  }
}
.recent-sermons .date {
  display: block;
  padding: 15px;
  color: #3c72fc;
  font-size: 24px;
  font-family: "Jost", sans-serif;
  font-weight: 300;
  background: #ebf1ff;
  text-align: center;
  margin-bottom: 20px;
}
@media all and (min-width: 1280px) {
  .recent-sermons .date {
    float: left;
    margin-right: 40px;
    margin-bottom: 0;
  }
}
.recent-sermons h3 {
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Jost", sans-serif;
  font-weight: 900;
  margin-bottom: 5px;
  color: #0f0d1d;
  overflow: hidden;
}
.recent-sermons p {
  font-size: 16px;
  color: #726f84;
  line-height: 32px;
  overflow: hidden;
  margin: 0;
}

.sermons-container {
  display: flex;
  flex-wrap: wrap;
}
.sermons-container a {
  display: block;
  width: 100%;
  padding: 25px 25px 40px;
  box-sizing: border-box;
  margin: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: relative;
  transition: all 0.33s ease-in-out;
  top: 0;
}
@media all and (min-width: 1280px) {
  .sermons-container a {
    width: calc(33% - 40px);
  }
}
.sermons-container a:hover, .sermons-container a:focus {
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
  top: -15px;
}
.sermons-container span.date {
  position: absolute;
  top: -31px;
  left: 0;
  background: #3c72fc;
  padding: 5px 20px;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.sermons-container h3 {
  font-size: 20px;
}
.sermons-container p {
  font-weight: normal;
}

.events {
  padding-top: 185px;
  padding-bottom: 100px;
  background-image: url("/assets/dist/images/wavy-lines.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 25% auto;
}
.events h2,
.events .callout-text {
  width: 100%;
  text-align: center;
}
.events .callout-text {
  margin-bottom: 50px;
}
.events ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.events ul li {
  width: 100%;
  box-sizing: border-box;
}
@media all and (min-width: 768px) {
  .events ul li {
    width: 50%;
    padding: 10px;
  }
  .events ul li:last-child, .events ul li:nth-last-child(2):nth-child(odd) {
    margin: -80px auto 0;
  }
}
@media all and (min-width: 1280px) {
  .events ul li {
    width: calc(33% - 30px);
    margin-right: 45px;
    padding: 0;
  }
  .events ul li:last-child, .events ul li:nth-last-child(2):nth-child(odd) {
    margin: 0;
  }
}
.events ul li:nth-child(3n) {
  margin-right: 0;
}
.events figure {
  margin: 0;
}
.events figure img {
  width: calc(100% - 40px);
}

.event-details {
  background: #fff;
  margin-left: 30px;
  top: -80px;
  position: relative;
  padding: 20px 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
}
@media all and (min-width: 1280px) {
  .event-details {
    height: 250px;
  }
}
.event-details .date {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  background: #3c72fc;
  padding: 5px 20px;
  position: absolute;
  top: -28px;
  font-weight: 600;
  left: 0;
}
.event-details h3 a {
  color: #0f0d1d;
  text-decoration: none;
  font-size: 28px;
  text-transform: uppercase;
  font-family: "Jost";
  letter-spacing: -0.02rem;
}
.event-details p {
  color: #726f84;
  font-size: 18px;
  line-height: 2rem;
}
.event-details > a {
  position: absolute;
  bottom: 30px;
  width: calc(100% - 80px);
  color: #0f0d1d;
  text-decoration: none;
}
.event-details > a:after {
  content: "";
  width: 40px;
  height: 3px;
  background: #0f0d1d;
  display: block;
  border-radius: 5px;
  position: absolute;
  bottom: -12px;
}

.callout-section {
  width: 100%;
  background: transparent url("/assets/dist/images/Callout-Background.png") no-repeat center center;
  background-size: cover;
  padding: 70px 0;
}
.callout-section .section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.callout-section h2 {
  font-family: "Jost", sans-serif;
  font-weight: 900;
  color: #fff;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: -0.02rem;
  width: 100%;
}
@media all and (min-width: 1280px) {
  .callout-section h2 {
    max-width: 50%;
  }
}

.ministries {
  padding-top: 185px;
  padding-bottom: 100px;
  background-image: url("/assets/dist/images/wavy-lines.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 25% auto;
}
.ministries .section-content {
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
}
.ministries h2 {
  text-align: center;
  margin-bottom: 60px;
  width: 100%;
}
.ministries .accordions {
  width: 100%;
}
@media all and (min-width: 1280px) {
  .ministries .accordions {
    width: calc(55% - 30px);
    margin-right: 30px;
  }
}
.ministries .accordion-details {
  text-align: left;
  width: 100%;
  margin-top: 40px;
}
@media all and (min-width: 1280px) {
  .ministries .accordion-details {
    width: 45%;
    margin-top: 0;
  }
}
.ministries .accordion-details figure {
  margin: 0 0 40px 0;
  max-width: 100%;
  position: relative;
}
.ministries .accordion-details figure::after, .ministries .accordion-details figure::before {
  content: "";
  display: block;
  position: absolute;
  width: 35%;
  height: 50%;
  background: #3c72fc;
  top: -10px;
  left: -10px;
  z-index: -1;
}
.ministries .accordion-details figure::after {
  top: auto;
  left: auto;
  right: -10px;
  bottom: -5px;
}
.ministries .accordion-details figure img {
  width: 100%;
}

.page-content .serve-nav-container h2 {
  text-align: center;
}
.page-content .serve-nav {
  padding: 30px 20px 40px;
  background: #fff;
  display: block;
  margin: 60px auto 0;
  width: 250px;
  text-align: center;
  border: 1px solid #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  list-style: none;
}
.page-content .serve-nav li button,
.page-content .serve-nav li a {
  display: block;
  appearance: none;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #f9f9f9;
  padding: 15px 0;
  font-weight: 600;
  background: transparent;
  transition: all 0.33s ease-in-out;
}
.page-content .serve-nav li button:hover, .page-content .serve-nav li button:focus,
.page-content .serve-nav li a:hover,
.page-content .serve-nav li a:focus {
  cursor: pointer;
  background: #f9f9f9;
  color: #3c72fc;
}
.page-content .serve-nav li:last-child button {
  border-bottom: 0;
}
.page-content h2 .subhead {
  font-style: italic;
  font-size: 24px;
  display: block;
  text-align: left;
}
.page-content .searching {
  padding-top: 185px;
  padding-bottom: 200px;
  background-image: url("/assets/dist/images/wavy-lines.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 25% auto;
}
.page-content .running-the-race h3 {
  font-size: 24px;
}
.page-content .running-the-race ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.page-content .running-the-race ul li {
  margin: 0 0 40px 0;
  padding: 0;
}
@media all and (min-width: 1280px) {
  .page-content .running-the-race ul li {
    display: flex;
    align-items: center;
  }
}
.page-content .running-the-race ul li:hover figure, .page-content .running-the-race ul li:focus figure {
  background: #ffc30b;
}
.page-content .running-the-race ul li figure {
  min-width: 100px;
  max-width: 100px;
  padding: 20px;
  border-radius: 150px;
  margin: 0 auto 20px auto;
  background: #f2f2f2;
  transition: all 0.33s ease-in-out;
}
@media all and (min-width: 1280px) {
  .page-content .running-the-race ul li figure {
    margin: 0 50px 0 0;
  }
}
.page-content .running-the-race ul li:before {
  content: none;
}

.accordions .accordion {
  text-align: left;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  background: #f2f4f8;
  margin: 0 0 15px 0;
}
.accordions .accordion.acc-open {
  background: #fff;
}
.accordions .accordion.acc-open .acc-content {
  height: auto;
  padding: 0px 40px 40px;
}
.accordions .accordion .acc-title {
  position: relative;
  font-size: 16px;
  color: #000;
  padding: 30px 40px;
  margin: 0;
}
.accordions .accordion .acc-title::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent url("/assets/dist/images/acc-plus.png") no-repeat center;
}
.accordions .accordion .acc-title:hover, .accordions .accordion .acc-title:focus {
  cursor: pointer;
  background: #fff;
}
.accordions .accordion .acc-content {
  height: 0;
  overflow: hidden;
  padding: 0;
}
.accordions .accordion .acc-content p {
  color: #0f0d1d;
}
.accordions .accordion.acc-open .acc-title::after {
  content: "";
  background-image: url("/assets/dist/images/acc-minus.png");
}

.fb-page {
  margin: 0 auto;
}

.map-section {
  padding: 0;
}

@media all and (min-width: 1280px) {
  .contact .section-content {
    display: flex;
    align-items: center;
  }
}
.contact .section-content .contact-avenues {
  width: 100vw;
  min-width: 0;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
@media all and (min-width: 1280px) {
  .contact .section-content .contact-avenues {
    width: 45%;
    min-width: 400px;
    position: static;
    transform: none;
  }
}
.contact .section-content .contact-avenues span[class*=fa] {
  width: 25px;
  text-align: center;
}
.contact .section-content .contact-avenues ul {
  background: #f2f2f2;
  padding: 20px 15px;
  border: 2px solid #dedede;
  margin: 0;
  border-right: 0;
  border-left: 0;
}
@media all and (min-width: 1280px) {
  .contact .section-content .contact-avenues ul {
    margin-left: 50px;
    border: 2px solid #dedede;
    border-radius: 10px;
  }
}
.contact .section-content .contact-avenues li:before {
  content: none;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 500px;
  width: 100%;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}

@media all and (min-width: 1280px) {
  .trinity-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.trinity-layout .tile {
  padding: 40px;
  border: 2px solid #fff;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
}
@media all and (min-width: 1280px) {
  .trinity-layout .tile {
    width: 50%;
  }
}
.trinity-layout .tile:hover, .trinity-layout .tile:focus {
  background: #f2f2f2;
}
.trinity-layout .tile:hover h3,
.trinity-layout .tile:hover p, .trinity-layout .tile:focus h3,
.trinity-layout .tile:focus p {
  color: #222;
}
.trinity-layout .tile.the-father {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -2px;
  box-sizing: border-box;
}
@media all and (min-width: 1280px) {
  .trinity-layout .tile.the-father {
    box-sizing: content-box;
  }
}
.trinity-layout .tile.the-father:hover .trinity-icon, .trinity-layout .tile.the-father:focus .trinity-icon {
  background: #d4af37;
}
.trinity-layout .tile.the-son {
  box-sizing: border-box;
  border-radius: 0;
}
@media all and (min-width: 1280px) {
  .trinity-layout .tile.the-son {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    margin-right: -1px;
  }
}
.trinity-layout .tile.the-son:hover .trinity-icon, .trinity-layout .tile.the-son:focus .trinity-icon {
  background: #8a0303;
}
.trinity-layout .tile.holy-spirit {
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
@media all and (min-width: 1280px) {
  .trinity-layout .tile.holy-spirit {
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
    margin-left: -1px;
  }
}
.trinity-layout .tile.holy-spirit:hover .trinity-icon, .trinity-layout .tile.holy-spirit:focus .trinity-icon {
  background: #fff;
}
.trinity-layout .tile h3 {
  text-align: center;
}
.trinity-layout .tile .trinity-icon {
  width: 100px;
  height: 100px;
  background: #f2f2f2;
  border-radius: 50%;
  padding: 30px;
  margin: 30px auto;
  transition: all 0.5s ease-in-out;
}
.trinity-layout .tile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

section.light-section.salvation {
  background: #fff url(/assets/dist/images/wings.png) no-repeat center;
  background-size: 130%;
}
@media all and (min-width: 1280px) {
  section.light-section.salvation {
    background-position: center bottom;
  }
}

/*

Footer Styles

*/
footer {
  background: #2c353c;
  color: #fff;
  padding-bottom: 150px;
  position: relative;
}
footer .footer-content {
  width: 100%;
  max-width: calc(100% - 80px);
  margin: 0 auto;
}
@media all and (min-width: 1280px) {
  footer .footer-content {
    max-width: calc(100% - 480px);
  }
}
footer .logo {
  border: 0;
  padding: 0 0 20px 0;
  text-align: left;
  max-width: 300px;
  justify-content: flex-start;
}
footer .contact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 60px;
}
footer .contact p {
  font-style: italic;
  max-width: 65%;
  text-align: left;
  margin: 0 auto;
}
footer .contact p a {
  display: block;
  text-align: right;
}
footer .contact a {
  color: #fff;
  display: block;
  text-decoration: none;
  font-weight: 600;
}
footer .contact hr {
  width: 100%;
  border: 0;
  background: rgba(255, 255, 255, 0.05);
  height: 2px;
  margin: 60px 0;
}
footer .attribution {
  background: #000;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 10px 0;
  text-align: center;
}
footer .attribution a {
  color: #ccc;
  text-decoration: none;
  font-size: 12px;
  text-transform: lowercase;
}
footer .copyright {
  text-align: center;
  white-space: nowrap;
  margin: 60px auto 0;
  min-width: 100%;
}

.newsletter-signup {
  margin: 0;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media all and (min-width: 1280px) {
  .newsletter-signup {
    width: auto;
    margin: 0 0 0 auto;
  }
}
.newsletter-signup > div {
  background: transparent;
  padding: 0;
  position: relative;
}
@media all and (min-width: 1280px) {
  .newsletter-signup > div {
    margin-left: 60px;
  }
}
.newsletter-signup > div h2 {
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 15px;
}

.mc-field-group {
  position: relative;
  height: 44px;
}
.mc-field-group label {
  position: absolute;
  top: 53%;
  left: 15px;
  color: #000;
  z-index: 1;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 1;
}
.signup-active .mc-field-group label {
  opacity: 0;
}
.mc-field-group .email {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.optionalParent {
  height: 10px;
}
.optionalParent input[type=submit] {
  border: 0;
  background: #960018;
  color: #fff;
  font-weight: bold;
  position: relative;
  top: -37px;
  left: calc(100% - 76px);
  height: 36px;
  border-radius: 2px;
}

.brandingLogo a {
  float: none;
  position: absolute;
  bottom: -55px;
  right: 0;
}

/*

Vendor Overrides

*/
.vcVerseLink {
  color: #f2f4f8;
  text-decoration: none;
}
.vcVerseLink:hover, .vcVerseLink:focus {
  color: #960018;
}
.light-section .vcVerseLink, .gray-section .vcVerseLink {
  color: #0f0d1d;
}
.light-section .vcVerseLink:hover, .light-section .vcVerseLink:focus, .gray-section .vcVerseLink:hover, .gray-section .vcVerseLink:focus {
  color: #960018;
}